/* login style */
#img_fondo{    
    width: 100%; 
    height: 100%;
    top:0;
    left:0;
    position:fixed;
    z-index: -1;   
}
.containe_login{
    max-width: 25rem;
    min-width: 10rem; 
    margin-top: 10%;
    padding: 30px;
    border-radius:15px;
    
}


/*page style*/
.backPage{
    width: 100%; 
    height: 100%;
    position:fixed;
    z-index: -1;   
    background-color: #f3f4f5;
    overflow: scroll;
}
.containerPage{
    max-width: 93%;
    margin: 3%;
    padding: 1.5%;
    justify-content: center;
    background-color: #fff;
    border-radius:4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 
                0px 1px 1px 0px rgba(0,0,0,0.14), 
                0px 1px 3px 0px rgba(0,0,0,0.12);
}


/* SMS Card style */
.sms{
    width: 90%;
    float: right;
    margin-bottom: 15px;
    background: #cdcdcd94;
    border-radius: 10px;
}
